<div class="flex flex-col h-full">
  <!-- <mat-toolbar color="primary" class="flex-shrink-0">
    <button (click)="onBack()" mat-icon-button>
      <mat-icon>arrow_back</mat-icon>
    </button>
    <button (click)="drawer.toggle()" mat-button>MENU</button>
  </mat-toolbar> -->
  <mat-drawer-container autosize class="flex-grow">
    <mat-drawer mode="side" #drawer opened>
      <div style="width: 240px" class="flex flex-col h-full overflow-x-hidden">
        <mat-action-list>
          <button (click)="onBack()" mat-list-item>
            <mat-icon matListItemIcon>arrow_back</mat-icon>
            <span>BACK</span>
          </button>
        </mat-action-list>
        <mat-divider></mat-divider>
        <div class="p-md flex flex-row gap-md">
          <div class="w-12 h-12 flex items-center justify-center">
            <mat-icon>person</mat-icon>
            <!-- <img class="w-12 h-12 object-contain rounded-full"
                            src="https://www.faceapp.com/static/img/content/compare/beard-example-after@3x.jpg"> -->
          </div>
          <div class="flex flex-col overflow-hidden">
            <span>{{ (user$ | async)?.name }}</span>
            <span class="text-gray-500 text-xs text-ellipsis overflow-hidden">{{
              (user$ | async)?.email
            }}</span>
          </div>
        </div>
        <mat-divider></mat-divider>

        <mat-nav-list class="!flex flex-col flex-grow">
          <ng-container *ngFor="let group of groups$ | async">
            <span class="mt-sm p-sm flex items-center gap-sm">
              <mat-icon
                [class.group-active]="group.active"
                [class.group-inactive]="!group.active"
                >{{ group.icon }}</mat-icon
              >
              {{ group.text }}
            </span>

            <mat-divider></mat-divider>
            <a
              [activated]="route.active"
              [routerLink]="route.url"
              *ngFor="let route of group.routes; trackBy: trackByKey"
              mat-list-item
            >
              <mat-icon matListItemIcon>{{ route.icon }}</mat-icon>
              <span>{{ route.text }} </span>
            </a>
          </ng-container>

          <a class="mt-auto" (click)="onLogout()" mat-list-item>
            <mat-icon matListItemIcon color="warn">logout</mat-icon>
            <span>LOGOUT</span>
          </a>
        </mat-nav-list>
      </div>
    </mat-drawer>
    <mat-drawer-content>
      <router-outlet></router-outlet>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
