import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UserState } from '../state/user/user.state';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  constructor(private readonly store: Store) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const token = this.Token;

    if (!token) {
      return next.handle(request);
    }

    const tokenizedRequest = request.clone({
      setHeaders: { Authorization: `Bearer ${token}` },
    });

    return next.handle(tokenizedRequest);
  }

  get Token(): string | undefined {
    return this.store.selectSnapshot(UserState.token);
  }
}
