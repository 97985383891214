import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from './common/layout/layout.component';
import { LoggedInGuard } from './guards/logged-in.guard';
import { LoggedOutGuard } from './guards/logged-out.guard';
import { CategoriesResolver } from './resolvers/categories.resolver';

@NgModule({
  imports: [
    RouterModule.forRoot([
      {
        path: 'auth',
        canActivate: [LoggedOutGuard],
        loadChildren: () =>
          import('./pages/auth/auth.module').then(
            (module) => module.AuthModule
          ),
      },
      {
        path: '',
        canActivate: [LoggedInGuard],
        resolve: {
          categories: CategoriesResolver,
        },
        component: LayoutComponent,
        children: [
          {
            path: 'categories',
            loadChildren: () =>
              import('./pages/categories/categories.module').then(
                (module) => module.CategoriesModule
              ),
          },
          {
            path: 'questions',
            loadChildren: () =>
              import('./pages/questions/questions.module').then(
                (module) => module.QuestionsModule
              ),
          },
          {
            path: 'golden-points',
            loadChildren: () =>
              import('./pages/golden-points/golden-points.module').then(
                (module) => module.GoldenPointsModule
              ),
          },
          {
            path: 'users',
            loadChildren: () =>
              import('./pages/users/users.module').then(
                (module) => module.UsersModule
              ),
          },
          {
            path: 'packages',
            loadChildren: () =>
              import('./pages/packages/packages.module').then(
                (module) => module.PackagesModule
              ),
          },
          {
            path: 'codes',
            loadChildren: () =>
              import('./pages/codes/codes.module').then(
                (module) => module.CodesModule
              ),
          },
          {
            path: 'user-reports',
            loadChildren: () =>
              import('./pages/user-reports/user-reports.module').then(
                (module) => module.UserReportsModule
              ),
          },
          {
            path: 'question-reports',
            loadChildren: () =>
              import('./pages/question-reports/question-reports.module').then(
                (module) => module.QuestionReportsModule
              ),
          },
          {
            path: 'notifications',
            loadChildren: () =>
              import('./pages/notifications/notifications.module').then(
                (module) => module.NotificationsModule
              ),
          },
        ],
      },
    ]),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
