import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { catchError, Observable, throwError } from 'rxjs';
import { UserLoggedOut } from '../state/user/user.action';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(private readonly router: Router, private readonly store: Store) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (!(error instanceof HttpErrorResponse)) {
          return throwError(() => error);
        }

        if (error.status != 401) {
          return throwError(() => error);
        }

        this.store.dispatch(new UserLoggedOut());
        this.router.navigate(['/auth/signin']);

        return throwError(() => error);
      })
    );
  }
}
