import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, map, tap } from 'rxjs';
import { CategoryGateway } from '../gateway/category-gateway.service';
import { CategoriesLoaded } from '../state/categories/category.action';

@Injectable({
  providedIn: 'root',
})
export class CategoriesResolver {
  constructor(
    private readonly categoryGateway: CategoryGateway,
    private readonly store: Store
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.categoryGateway.categories().pipe(
      tap({
        next: (result) =>
          this.store.dispatch(new CategoriesLoaded(result.data)),
      }),
      map(() => true)
    );
  }
}
