import { isDevMode, NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { CategoryState } from './categories/category.state';
import { UserState } from './user/user.state';

@NgModule({
  imports: [
    NgxsModule.forRoot([UserState, CategoryState], {
      developmentMode: isDevMode(),
      selectorOptions: { injectContainerState: false },
    }),
  ],
  exports: [NgxsModule],
})
export class StateModule {}
